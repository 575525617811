import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '@components/contact';
import Hero from '../components/hero';
import Layout from '@components/layout';
import MarkdownContent from '../components/markdown-content/MarkdownContent';

const OmCookies = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      headLine,
      pageContactHeader,
      pageContent,
    },
  } = data;

  const { bodyText } = pageContent[0].bodyText;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[{ path: '/', name: 'Hem' }]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
      />
      <MarkdownContent markdown={bodyText} />
      <Contact headerText={pageContactHeader} isLargeContact={true} />
    </Layout>
  );
};

export default OmCookies;

OmCookies.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query OmCookiesQuery {
    contentfulPage(pageUrl: { eq: "/om-cookies" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
